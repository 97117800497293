




























































import {
  defineComponent,
  ref,
  computed,
  PropType
} from '@nuxtjs/composition-api';

import useWindowResize from '~/hooks/useResizeWindow.js';

type ImageApiData = {
  media?: { media_path: string; media_file_name: string }[];
  custom_fields: {
    key: string;
    value_string: string;
    checkbox: number;
    value_select: string;
  }[];
};

const customFieldsValues = {
  IMAGE_FORMAT: 'image_format',
  DARK_MODE: 'dark_mode',
  PHOTOGRAPHER_NAME: 'photographer_name',
  SOCIAL_HANDLE: 'social_handle',
  CAMERA: 'camera',
  LENS: 'lens',
  LOCATION: 'location',
  SHUTTER_SPEED: 'shutterspeed',
  ISO: 'iso',
  APERTURE: 'aperture',
  FOCAL_LENGTH: 'focal_length'
};

export default defineComponent({
  name: 'KEImage',
  props: {
    data: {
      type: Object as PropType<ImageApiData>,
      required: true,
      default: () => ({})
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();

    const isShowingInfo = ref(false);

    const imageMedia = computed(
      () =>
        props.data.media?.[0] || {
          media_path: '',
          media_file_name: ''
        }
    );

    const getCustomField = (key: string) =>
      props.data.custom_fields?.find((field) => field.key === key) || null;

    const darkMode = computed(
      () =>
        Boolean(getCustomField(customFieldsValues.DARK_MODE)?.checkbox) || false
    );

    const photographerInfo = computed(() => ({
      name:
        getCustomField(customFieldsValues.PHOTOGRAPHER_NAME)?.value_string ||
        '',
      socialName:
        getCustomField(customFieldsValues.SOCIAL_HANDLE)?.value_string || ''
    }));

    const cameraInfo = computed(() => ({
      model: getCustomField(customFieldsValues.CAMERA)?.value_string || '',
      lens: getCustomField(customFieldsValues.LENS)?.value_string || ''
    }));

    const shutterInfo = computed(() => ({
      iso: getCustomField(customFieldsValues.ISO)?.value_string || '',
      shutterSpeed:
        getCustomField(customFieldsValues.SHUTTER_SPEED)?.value_string || '',
      aperture: getCustomField(customFieldsValues.APERTURE)?.value_string || '',
      focalLength:
        getCustomField(customFieldsValues.FOCAL_LENGTH)?.value_string || ''
    }));

    const location = computed(
      () => getCustomField(customFieldsValues.LOCATION)?.value_string || ''
    );

    const hasInfo = computed(() =>
      [
        photographerInfo.value.socialName,
        ...Object.values(cameraInfo.value),
        ...Object.values(shutterInfo.value)
      ].some((value) => Boolean(value))
    );

    const handleToggleShowInfo = () => {
      isShowingInfo.value = !isShowingInfo.value;
    };

    return {
      isDesktop,
      isShowingInfo,
      hasInfo,
      imageMedia,
      darkMode,
      photographerInfo,
      cameraInfo,
      shutterInfo,
      location,
      handleToggleShowInfo
    };
  }
});
