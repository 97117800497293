import { render, staticRenderFns } from "./ContentHighlight.vue?vue&type=template&id=7ac6a530&scoped=true&"
import script from "./ContentHighlight.vue?vue&type=script&lang=ts&"
export * from "./ContentHighlight.vue?vue&type=script&lang=ts&"
import style0 from "./ContentHighlight.vue?vue&type=style&index=0&id=7ac6a530&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ac6a530",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsComponentsKEImage: require('/var/www/packages/theme/components/cms-components/KEImage.vue').default,CmsComponentsKEText: require('/var/www/packages/theme/components/cms-components/KEText.vue').default})
