import { render, staticRenderFns } from "./KEImage.vue?vue&type=template&id=64984fdc&scoped=true&"
import script from "./KEImage.vue?vue&type=script&lang=ts&"
export * from "./KEImage.vue?vue&type=script&lang=ts&"
import style0 from "./KEImage.vue?vue&type=style&index=0&id=64984fdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64984fdc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsImageOrFallback: require('/var/www/packages/theme/components/atoms/ImageOrFallback.vue').default,IconsCameraIcon24px: require('/var/www/packages/theme/components/icons/camera/CameraIcon24px.vue').default,IconsCameraIcon16px: require('/var/www/packages/theme/components/icons/camera/CameraIcon16px.vue').default,IconsCross24px: require('/var/www/packages/theme/components/icons/cross/Cross24px.vue').default,IconsCross16px: require('/var/www/packages/theme/components/icons/cross/Cross16px.vue').default})
