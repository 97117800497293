














import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ContentHighlight',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const imageData = computed(() => ({
      media: props.data.media?.length > 0 ? props.data.media : props.data.urls
    }));

    const textData = computed(() => ({
      ...props.data,
      urls: props.data.urls?.length
        ? [
            {
              url: props.data.urls[0].url,
              title:
                props.data.custom_fields?.find(
                  (field) => field.key === 'button_text'
                )?.value_string ?? '',
              target: props.data.urls[0].target
            }
          ]
        : []
    }));

    const imageOrder = computed(() => {
      if (props.data.code === 'image_contenthighlight_left') return 0;
      else return 1;
    });

    return {
      imageData,
      textData,
      imageOrder
    };
  }
});
