import { render, staticRenderFns } from "./KEText.vue?vue&type=template&id=bac833ec&scoped=true&"
import script from "./KEText.vue?vue&type=script&lang=ts&"
export * from "./KEText.vue?vue&type=script&lang=ts&"
import style0 from "./KEText.vue?vue&type=style&index=0&id=bac833ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bac833ec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsMarkdownRender: require('/var/www/packages/theme/components/atoms/MarkdownRender.vue').default,AtomsKEButton: require('/var/www/packages/theme/components/atoms/KEButton.vue').default,MoleculesCmsCustomButtonWrapper: require('/var/www/packages/theme/components/molecules/CmsCustomButtonWrapper.vue').default,MoleculesCmsUrlWrapper: require('/var/www/packages/theme/components/molecules/CmsUrlWrapper.vue').default})
