

































import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import { SfBadge } from '@storefront-ui/vue';
import { getCustomFieldByKey } from '~/helpers/utils';
import useUrlFormatter from '~/hooks/useUrlFormatter';

type TextData = {
  id: number;
  number_of_columns: number;
  description?: string;
  plain_text?: string;
  markdown_content?: string;
  urls?: {
    title: string;
    url: string;
  }[];
  custom_fields?: {
    key: string;
    value_string: string;
  }[];
};

export default defineComponent({
  name: 'KEText',
  props: {
    data: {
      type: Object as PropType<TextData>,
      required: true,
      default: () => [
        {
          id: 0,
          number_of_columns: 1,
          description: '',
          plain_text: '',
          markdown_content: '',
          urls: [
            {
              title: '',
              url: '',
              media_path: ''
            }
          ],
          custom_fields: [
            {
              key: '',
              value_string: ''
            }
          ]
        }
      ]
    }
  },
  components: {
    SfBadge
  },
  setup(props) {
    const formatUrl = useUrlFormatter();

    const getCustomField = (key: string) => {
      return getCustomFieldByKey(props.data.custom_fields, key);
    };

    const linkData = computed(() => props.data.urls?.[0]);

    const labelColor = getCustomField('label_color')?.value_select || 'grey';
    const labelText = getCustomField('label_text')?.value_string || '';
    const title = getCustomField('title')?.value_string || '';

    return {
      getCustomField,
      linkData,
      labelColor,
      labelText,
      title,
      formatUrl
    };
  }
});
